<template>
  <main>
    <div class="error_wrap">
      <div class="error_box">
        <h1>{{ $t('layouts.error.001') }}</h1>
        <h2>
          {{ $t('layouts.error.002') }}<br>
          {{ $t('layouts.error.003') }}
        </h2>
        <NuxtLink :to="localePath( 'index')"
        ><span>{{ $t('layouts.error.004') }}</span></NuxtLink
        >
      </div>
    </div>
  </main>
</template>

<script setup>
const localePath = useLocalePath()

useHead({
  meta: [
    {
      hid: 'og:url',
      name: 'og:url',
      content: process.env.BASE_URL + localePath(useRoute().fullPath)
    }
  ]
})
</script>

<style lang="scss" scoped>
.error_wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  padding: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.error_box {
  text-align: center;
  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;
  }
  > h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    margin-top: 8px;
  }
  > a {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 295px;
    margin-top: 40px;
    background-color: #002c5f;
    padding: 10px 16px 11px;
    text-align: center;
    > span {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #fff;
    }
  }
}

@media (min-width: 768px) {
  .error_box {
    > h1 {
      font-size: 24px;
      line-height: 36px;
    }
    > h2 {
      margin-top: 20px;
    }
    > a {
      max-width: 140px;
      padding: 6px 16px;
      > span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>